<template>
  <div id="map">
    <div id="map-canvas"></div>
  </div>
</template>

<script>
  import { Loader } from "@googlemaps/js-api-loader";
  import logo from "../../assets/images/logo.png";
  import cluster from "../../assets/images/cluster.png";
  export default {
    props: ["stores", "country", "selectedStore"],
    data() {
      return {
        map: null,
        markers: [],
        clusterStyle: {
          styles: [
            {
              url: cluster,
              width: 48,
              height: 48,
              textSize: 15,
              fontWeight: "bold",
              textColor: "#ECDEBB",
              textAlign: "center",
            },
          ],
        },
        cluster: [],
      };
    },
    async mounted() {
      let self = this;
      const loader = new Loader({
        apiKey: "AIzaSyApHRk8TCgZc35NQ7ZeeUGLDjlk9k79dUM",
        version: "weekly",
        libraries: ["places"],
      });

      await loader.load().then(() => {
        self.initMap();
        // self.addMapMarkers();
      });
    },
    methods: {
      /**
       * Centra la mappa e zoomma nella posizione in cui si trova lo store s passato come parametro
       */
      zoomAndCenter(s) {
        var posizione = this.getPosition(s.U_THE_LATITUDINE, s.U_THE_LONGITUDINE); //SE mancano le coordinate viene fatto puntare il centro nazionale
        this.map.setCenter(posizione);
        this.map.setZoom(16);
        // CICLO FOR PER CONTROLLARE TUTTI I MARKER
        for (let i = 0; i < this.markers.length; i++) {
          var NameString = "";
          if (
            this.markers[i].storeData !== null &&
            s.storeData == this.markers[i].storeData
          ) {
            const self = this,
              m = this.markers[i],
              s = m.storeData,
              c = m.storeData;
            if (s.BuildingFloorRoom === "" || s.BuildingFloorRoom == null) {
              NameString = s.U_THE_RAG_SOCIALE;
            } else {
              NameString = s.BuildingFloorRoom;
            }
            let iw_content =
              '<div class="card-body white-text">' +
              '<div class="d-flex row">' +
              '<div class="col justify-content-center align-items-center info-address-column" style="color: black;">' +
              '<p class="white-text" >' +
              "<b>" +
              NameString +
              "</b></br>" +
              '<div class="separator"></div>' +
              s.Street +
              ", <br /> " +
              s.ZipCode +
              " " +
              s.City +
              "<br />" +
              '<a href="mailto:' +
              c.E_Mail +
              '" class="contact-data white-text" >' +
              c.E_Mail +
              "</a><br />" +
              '<a class="contact-data white-text" href="tel:' +
              c.Phone1 +
              '">' +
              c.Phone1 +
              "</a>" +
              "</p>" +
              "</div>" +
              // '<div class="col justify-content-center align-items-center info-image-column">'+
              // '<img class="img-fluid store-image '+s.CardCode+s.AddressName+'" onerror="setDefaultImageStore(this)" src="/images/stores_images/'+s.CardCode+s.AddressName+'.jpg" alt="'+s.BuildingFloorRoom+'" />'+
              "</div>" +
              "</div>" +
              '<div class="clearfix"></div>' +
              "</div>";
            // console.log('selected store'+i);
            // eslint-disable-next-line no-undef
            var iw = new google.maps.InfoWindow({
              content: iw_content,
              maxWidth: 300,
            });
            // RENDO VISIBILE IL MARKER DELLO STORE SELEZIONATO E CHIUDO GLI ALTRI
            if (typeof self.infoWindow != "undefined") {
              self.infoWindow.close();
            }
            iw.open(self.map, m);
            self.infoWindow = iw;
            // eslint-disable-next-line no-undef
            google.maps.event.addListener(iw, "domready", function () {
              // var el = document.getElementsByClassName("gm-style-iw")[0];
              // wr = el.parentElement,
              // bk = wr.firstChild,
              // close = wr.getElementsByTagName("button")[0],
              // closeIcon = document.createElement("i");
            });
          }
        }
      },
      /**
       * Inizializzo una mappa pulita
       */
      initMap: function () {
        // eslint-disable-next-line no-undef
        this.map = new google.maps.Map(document.getElementById("map"), {
          zoom: 8,
          center: {
            lat: 0,
            lng: 0,
          },
          // minZoom: 8,
          // maxZoom: 15,
        });
      },
      /**
       * Aggiungo i marker per ogni store in stores, i marker sono gestiti tramite MarkerClusterer
       */
      addMapMarkers: function () {
        // reset di eventuali markers precedenti
        if (typeof this.cluster.setMap == "function") {
          this.cluster.clearMarkers();
          this.cluster = {};
        }
        this.markers = [];
        //inserimento nuovi markers
        for (let i = 0; i < this.stores.length; i++) {
          let m,
            store = this.stores[i];
          if (
            store.U_THE_LATITUDINE &&
            store.U_THE_LATITUDINE != 0 &&
            store.U_THE_LONGITUDINE &&
            store.U_THE_LONGITUDINE != 0
          ) {
            // eslint-disable-next-line no-undef
            m = new google.maps.Marker({
              position: this.getPosition(
                store.U_THE_LATITUDINE,
                store.U_THE_LONGITUDINE
              ),
              map: this.map,
              icon: logo,
            });
            m.storeData = store;
            this.markers.push(m);
          }
        }
        for (var j = 0; j < this.markers.length; j++) {
          var NameString = "";
          let PhoneNumber = "";
          var MailString = "";
          if (this.markers[j].storeData.contatto !== null) {
            const self = this,
              m = this.markers[j],
              s = m.storeData,
              c = m.storeData;
            if (s.BuildingFloorRoom === "" || s.BuildingFloorRoom == null) {
              NameString = s.U_THE_RAG_SOCIALE;
            } else {
              NameString = s.BuildingFloorRoom;
            }
            if (c.E_Mail === "" || c.E_Mail == null) {
              MailString = "";
            } else {
              MailString = c.E_Mail;
            }
            if (c.Phone1 === "" || c.Phone1 == null) {
              PhoneNumber = "";
            } else {
              PhoneNumber = c.Phone1;
            }
            var iw_content =
              '<div class="card-body white-text">' +
              '<div class="d-flex row">' +
              '<div class="col justify-content-center align-items-center info-address-column" >' +
              '<p class="white-text" >' +
              "<b>" +
              NameString +
              "</b></br>" +
              '<div class="separator"></div>' +
              s.Street +
              ", <br /> " +
              s.ZipCode +
              " " +
              s.City +
              "<br />" +
              '<a href="mailto:' +
              MailString +
              '" class="contact-data white-text" >' +
              MailString +
              "</a><br />" +
              '<a class="contact-data white-text" href="tel:' +
              PhoneNumber +
              '">' +
              PhoneNumber +
              "</a>" +
              "</p>" +
              "</div>" +
              // '<div class="col justify-content-center align-items-center info-image-column">'+
              // '<img class="img-fluid store-image '+s.CardCode+s.AddressName+'" onerror="setDefaultImageStore(this)" src="/images/stores_images/'+s.CardCode+s.AddressName+'.jpg" alt="'+s.BuildingFloorRoom+'" />'+
              "</div>" +
              "</div>" +
              '<div class="clearfix"></div>' +
              "</div>";
            // eslint-disable-next-line no-undef
            const iw = new google.maps.InfoWindow({
              content: iw_content,
              maxWidth: 350,
            });
            m.addListener("click", function () {
              if (typeof self.infoWindow != "undefined") {
                self.infoWindow.close();
              }
              iw.open(self.map, m);
              self.infoWindow = iw;
            });
            // eslint-disable-next-line no-undef
            google.maps.event.addListener(iw, "domready", function () {
              // var el = document.getElementsByClassName("gm-style-iw")[0],
              //   wr = el.parentElement,
              //   bk = wr.firstChild,
              //   close = wr.getElementsByTagName("button")[0],
              //   closeIcon = document.createElement("i");
            });
          }
        }
        // eslint-disable-next-line no-undef
        this.cluster = new MarkerClusterer(
          this.map,
          this.markers,
          this.clusterStyle
        );
      },
      getPosition: function (lat, lng) {
        // eslint-disable-next-line no-undef
        return new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
      },
      /**
       * Centra la mappa nel country da cui si connette lo user
       */
      setCenterPosition: function () {
        //calcolo la posizione su cui centrare la mappa e relativo zoom
        var self = this,
          // eslint-disable-next-line no-undef
          geocoder = new google.maps.Geocoder();
        // function getCountry(components) {
        //   var country = {};
        //   for (var i = 0; i < components.length; i++) {
        //     if (components[i].types[0] == "country") {
        //       country = components[i];
        //     }
        //   }
        //   return country;
        // }
        if (this.country) {
          if (geocoder) {
            geocoder.geocode(
              { address: this.country.Nome },
              // eslint-disable-next-line no-unused-vars
              function (results, status) {
                // console.log(results, status);
                self.map.fitBounds(results[0].geometry.bounds);
              }
            );
          }
        }
      },
    },
    watch: {
      async country() {
        this.setCenterPosition();
      },
      stores() {
        this.addMapMarkers();
      },
      selectedStore(newVal) {
        this.zoomAndCenter(newVal);
      },
    },
  };
</script>

<style lang="less" >
  @media (max-width: 992px) {
    #map {
      width: calc(100vw);
      height: calc(100vh / 2.1);
    }
  }

  @media (min-width: 993px) {
    #map {
      width: ~"calc(75vw - 20px)";
      height: ~"calc(100vh - 100px)";
    }
  }

  #map {
    // width: calc(100vw - 500px);
    // height: calc(100vh - 100px);
    margin-top: 20px;

    .gm-style .gm-style-iw-c,
    .gm-style-iw-d,
    .card-body {
      background-color: @--color-primary;
      color: #fff;
      overflow: hidden !important;
      padding-right: 5px;
      a,
      a:visited {
        color: @--color-text-primary;
      }
      .gm-ui-hover-effect img {
        filter: invert(1);
      }
    }

    .gm-style .gm-style-iw-t::after {
      background: @--color-primary;
    }
  }
</style>