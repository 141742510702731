<template>
  <div class="container">
    <el-container v-if="windowWidth < 992" direction="vertical">
      <el-main class="main-class">
        <Map
          :stores="filteredStores"
          :country="country"
          :selectedStore="selectedStore"
        />
      </el-main>
      <el-aside class="aside-class">
        <Menu
          :stores="stores"
          @storeUpdate="setFilteredStores"
          @zoomToStore="setSelectedStore"
        />
      </el-aside>
    </el-container>
    <el-container v-else>
      <el-main class="main-class">
        <Map
          :stores="filteredStores"
          :country="country"
          :selectedStore="selectedStore"
        />
      </el-main>
      <el-aside class="aside-class">
        <Menu
          :stores="stores"
          @storeUpdate="setFilteredStores"
          @zoomToStore="setSelectedStore"
        />
      </el-aside>
    </el-container>
  </div>
</template>

<script>
  import components from "../components/store-locator";
  import { mapActions } from "vuex";
  export default {
    data() {
      return {
        country: null,
        stores: [],
        countries: [],
        filteredStores: [],
        selectedStore: {},
        windowWidth: window.innerWidth,
      };
    },
    components: components,
    mounted: async function () {
      let country = await this.getUserCountry(),
        stores = await this.getStores();
      this.country = country;
      this.stores = stores.data.stores;
      this.countries = stores.data.countryList;
      this.setFilteredStores(this.stores);
    },
    methods: {
      ...mapActions("user", { getUserCountry: "getCountry" }),
      ...mapActions({
        getCountryStoreActive: "getCountryStoresActive",
        getStores: "getStores",
      }),
      setFilteredStores(stores) {
        this.filteredStores = stores;
      },
      setSelectedStore(store) {
        this.selectedStore = store;
      },
    },
  };
</script>

<style lang="less" >
  .container {
    width: 100%;
  }
  .main-class {
    width: 75vw;
    // min-width: 500px;
    // border: solid 2px green;
    // box-sizing: border-box;
    height: auto;
  }
  .aside-class {
    width: 25vw !important;
    // min-width: 500px;
    // border: solid 2px green;
    // box-sizing: border-box;
  }

  @media (max-width: 992px) {
    .aside-class {
      width: 100vw !important;
      // min-width: 500px;
      // border: solid 2px green;
      // box-sizing: border-box;
    }
    .main-class {
      width: 100vw !important;
      // min-width: 500px;
      // border: solid 2px green;
      // box-sizing: border-box;
      height: 50vh;
    }
  }
</style>>
