<template>
  <div id="stores">
    <el-card shadow="never" class="r-card">
      <div slot="header" class="confirm-title">
        <span>TROVA IL PUNTO VENDITA S-O PIÙ VICINO A TE</span>
      </div>
      <div class="filters">
        <el-input
          placeholder="CERCA CITTÀ"
          v-model="storeFilter"
          @input="$emit('storeUpdate', filteredStores)"
        ></el-input>
        <span class="total-stores">{{ filteredStores.length }} NEGOZI TROVATI</span>
      </div>
      <div
        class="stores-list"
        ref="storeList"
        :style="{ height: `${storeListHeight}px` }"
      >
        <div
          class="store-item"
          v-for="s in filteredStores"
          :key="s.CardCode + s.AddressName"
          @click="$emit('zoomToStore', s)"
        >
          <span class="ragione-sociale">{{ s.BuildingFloorRoom }}</span>
          <br />
          <small class="indirizzo">
            {{ s.City }}, {{ s.Street }}
            <a
              :href="`mailto:${s.E_Mail}`"
            >{{ s.E_Mail }}</a>
          </small>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import jslinq from "jslinq";
  import axios from "axios";
  export default {
    props: ["stores"],
    data() {
      return {
        storeFilter: "",
        windowSize: 0,
        countries: [],
      };
    },
    mounted() {
      window.addEventListener("resize", this.setSize);
      this.windowSize = window.innerHeight;
      // this.$nextTick(() => {
      //   this.$emit("storeUpdate", this.filteredStores);
      // });
    },
    methods: {
      setSize() {
        this.windowSize = window.innerHeight;
      },
    },
    computed: {
      filteredStores() {
        let s = jslinq(this.stores);
        if (this.storeFilter != "") {
          return s
            .where((store) => {
              let c = store.City.toUpperCase();
              return c.indexOf(this.storeFilter.toUpperCase()) >= 0;
            })
            .toList();
        }
        return this.stores;
      },
      storeListHeight() {
        // console.log("setting list height");
        if (this.windowSize > 0) {
          return this.windowSize - this.$refs.storeList.offsetTop;
        }
        return 0;
      },
      allCountries() {
        let s = jslinq(this.stores);
        return s
          .select((store) => {
            return store.Country;
          })
          .distinct()
          .toList();
      },
    },
    watch: {
      windowSize() {
        // console.log("settingSize");
        this.setSize();
      },
      async allCountries(newVal) {
        let query = "",
          a = axios.create();

        delete a.defaults.headers.common["site-id"];
        delete a.defaults.headers.common["line-id"];
        delete a.defaults.headers.common["Authorization"];

        for (let i = 0; i < newVal.length; i++) {
          const cc = newVal[i];
          query += `${cc};`;
        }
        this.countries = await a.get(
          `https://restcountries.eu/rest/v2/alpha?codes=${query}`
        );
      },
    },
  };
</script>

<style lang="less">
  @media (max-width: 992px) {
    #stores {
      width: 100vw;
    }
  }

  @media (min-width: 993px) {
    #stores {
      width: ~"calc(25vw + 20)";
      margin-right: 0% !important;
    }
  }
  .el-aside {
    max-height: ~"calc(100vh - 80px)";
    overflow-y: hidden;
  }
  #stores {
    .el-card__header {
      padding: 18px 0px;
      position: sticky;
      // top: 0px;
    }

    .el-card__body {
      padding: 0px 0;
    }

    .filters {
      display: flex;
      flex-direction: column;
      padding: 20px;
      .total-stores {
        align-self: flex-start;
        padding-top: 8px;
        font-size: 14px;
        color: @--color-primary;
      }
    }

    .stores-list {
      // padding-bottom: 15px;
      overflow-y: scroll;
      .store-item {
        padding: 10px 8px;
        border-top: 1px solid @--color-primary;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        cursor: pointer;

        &:nth-child(even) {
          background-color: #fff;
        }
        &:last-child {
          border-bottom: 1px solid @--color-primary;
          margin-bottom: 15px;
        }
        .ragione-sociale {
          font-weight: bold;
        }
      }
    }
  }
</style>